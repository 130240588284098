import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { logoutUser } from '../../store/authentication/actions'
import CartIcon from '../../elements/CartIcon/CartIcon'
import CartQuickView from '../../elements/CartQuickView/CartQuickView'
import { downloadMenuLink, allergyGuideLink, allergyGuideLinkProd, downloadMenuLinkProd } from '../../constants/headers'
import { currentUserIsClient } from '../../store/helpers/userRoleManager'
import styles from './Header.css'
const SHOW_CART = false
const Header = ({ classes, signOut, currentUser }) => {
  const [showCartDetails, setShowCartDetails] = useState(false)
  const [cartItems] = useState([])
  const isClientUser = currentUserIsClient(currentUser)

  const quickViewCart = () => {
    setShowCartDetails(!showCartDetails)
  }

  const proceedToCheckout = () => {

  }
  const downloadMenuHandler = () => window.open(process.env.REACT_APP_SUITES_API_BASE_URL.includes('staging') ? downloadMenuLink : downloadMenuLinkProd, '_blank')

  const downloadAllegyGuideHandler = () => window.open(process.env.REACT_APP_SUITES_API_BASE_URL.includes('staging') ? allergyGuideLink : allergyGuideLinkProd, '_blank')

  return (
    <div id="header" className={classes.headerGrid}>
      <div className={classes.logoContainer}><img src="/msgLogo.png" alt="" className={classes.suitesImage} /></div>
      <div className={classes.gloablNavContainer}>
        <div className={classes.headerLinksContainer} onClick={downloadMenuHandler}>Download Menu</div>
        <div className={classes.headerLinksContainer} onClick={downloadAllegyGuideHandler}>Allergy Guide</div>
        {isClientUser && <div className={classes.headerLinksContainer}>
          <Link to={'/my-account'}>
            <span>Account</span>
          </Link>
        </div>}
        <div className={classes.headerLinksContainer} onClick={signOut}>Log Out</div>
        {
          SHOW_CART && <div className={classes.cartQuickViewContainer}>
            <CartIcon viewCart={quickViewCart} />
            { showCartDetails && <CartQuickView cartItems={cartItems} proceedToCheckout={proceedToCheckout} /> }
          </div>
        }
      </div>
    </div>
  )
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  signOut: PropTypes.func,
  currentUser: PropTypes.object
}

const mapDispatchToProps = {
  signOut: logoutUser
}

const mapStateToProps = (state) => {
  return {
  }
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Header))
